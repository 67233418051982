.leftSideBar {
    width: 20%;
    padding: 20px;
    background-color: #fa0000;
    position: fixed;
    top: 50px;
    left: 0;
    bottom: 0;
  }
  
  .main-content {
    margin-left: 20%;
  }